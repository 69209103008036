var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col items-center w-full"},[_c('form',{staticClass:"w-full submit-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[(_vm.getBillboards.length)?_c('div',{staticClass:"pb-2"},[_c('div',{staticClass:"text-sm md:text-lg mb-2 opacity-50"},[_vm._v("Период размещения")]),_c('v-date-picker',{attrs:{"mode":"date","masks":_vm.masks,"min-date":new Date(),"is-range":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"flex justify-center items-center"},[_c('input',_vm._g({staticClass:"input-field",class:{
                invalid: _vm.$v.dateRange.$dirty && !_vm.$v.dateRange.required,
              },domProps:{"value":inputValue.start}},inputEvents.start)),_c('svg',{staticClass:"w-12 h-12 mx-4 opacity-50",attrs:{"fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M14 5l7 7m0 0l-7 7m7-7H3"}})]),_c('input',_vm._g({staticClass:"input-field",domProps:{"value":inputValue.end}},inputEvents.end))]),(_vm.$v.dateRange.$dirty && !_vm.$v.dateRange.required)?_c('div',{staticClass:"text-red-500 h-6 text-sm"},[_vm._v(" Пожалуйста, введите период размещения ")]):_vm._e()]}}],null,false,4059933843),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1):_vm._e(),_c('div',{staticClass:"pb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.username),expression:"username",modifiers:{"trim":true}}],staticClass:"input-field",class:{ invalid: _vm.$v.username.$dirty && !_vm.$v.username.required },attrs:{"type":"text","placeholder":"Ваше имя"},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.username.$dirty && !_vm.$v.username.required)?_c('div',{staticClass:"text-red-500 h-6 text-sm"},[_vm._v(" Пожалуйста, введите свое имя ")]):_vm._e()]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}}],staticClass:"input-field",class:{
          invalid:
            (_vm.$v.email.$dirty && !_vm.$v.email.required) ||
            (_vm.$v.email.$dirty && !_vm.$v.email.email),
        },attrs:{"type":"email","placeholder":"Ваша электронная почта"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.email.$dirty && !_vm.$v.email.required)?_c('div',{staticClass:"text-red-500 h-6 text-sm"},[_vm._v(" Пожалуйста, введите свою эл. почту ")]):_vm._e(),(_vm.$v.email.$dirty && !_vm.$v.email.email)?_c('div',{staticClass:"text-red-500 h-6 text-sm"},[_vm._v(" Пожалуйста, введите корректную ел. почту ")]):_vm._e()]),_c('div',{staticClass:"py-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumber),expression:"phoneNumber"},{name:"mask",rawName:"v-mask",value:('+375 (##) ###-##-##'),expression:"'+375 (##) ###-##-##'"}],staticClass:"input-field",class:{
          invalid:
            (_vm.$v.phoneNumber.$dirty && !_vm.$v.phoneNumber.required) ||
            (_vm.$v.phoneNumber.$dirty && !_vm.$v.phoneNumber.minLength),
        },attrs:{"type":"tel","placeholder":"Ваш номер телефона"},domProps:{"value":(_vm.phoneNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phoneNumber=$event.target.value}}}),(_vm.$v.phoneNumber.$dirty && !_vm.$v.phoneNumber.required)?_c('div',{staticClass:"text-red-500 h-6 text-sm"},[_vm._v(" Пожалуйста, введите свой номер телефона ")]):(_vm.$v.phoneNumber.$dirty && !_vm.$v.phoneNumber.minLength)?_c('div',{staticClass:"text-red-500 h-6 text-sm"},[_vm._v(" Пожалуйста, введите корректный номер телефона ")]):_vm._e()]),_c('div',{staticClass:"flex w-full text-white text-2xl pt-2"},[_c('button',{staticClass:"bg-button-blue text-white w-full py-3 btn text-lg focus:outline-none 3xl:text-3xl xl:text-lg lg:text-sm hvr-underline-from-center pulse-single",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }